<template>
    <div class="centered-message w-100">
        <h1 class="text-primary mb-5">Stella™ is Currently Offline</h1>
        <div class="errm">
            <p>Stella is undergoing system updates and/or experiencing technical issues.</p>
            <p>Our team is working diligently to restore service as quickly as possible.</p>
            <p>We appreciate your patience during this time.</p>
            <p class="mt-4">
                For any questions or assistance, please reach out to our customer service team:
            </p>
            <p class="mt-4">
                <strong>
                    US:
                    <a href="mailto:customerservice@staar.com"> customerservice@staar.com </a>
                </strong>
            </p>
            <p>
                <strong>
                    International:
                    <a href="mailto:customerservice.ag@staar.com"> customerservice.ag@staar.com </a>
                </strong>
            </p>
        </div>
    </div>
</template>
<script>
import {mapState} from 'vuex';

export default {
    name: 'Unavailable',
    data() {
        return {};
    },
    computed: {
        ...mapState({
            noapi: (state) => state.app.noapi,
        }),
    },
    watch: {
        /**
         * Watch the noapi value from state, which will get reset to true once a request succeeds.
         * @param {boolean} nval
         * @return {Promise<void>}
         */
        async noapi(nval) {
            if (!nval) {
                await this.$router.push({name: 'Dashboard'});
                location.reload();
            }
        },
    },
};
</script>
<style scoped>
.errm {
    font-size: 30px;
}
</style>
